import React, { useState, useEffect } from "react"
import Navbar from "./navbar"
import { Header } from "../styledComponent/sc"

export default function MainHeader() {
  const [color, setColor] = useState("transparent")
  const [shadow, setShadow] = useState(false)
  const listenScrollEvent = e => {
  
    if (window.scrollY > 75) {
      setColor("#753422")
      setShadow(true)
    } else {
      setColor("transparent")
      setShadow(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)

    return () => {
      window.removeEventListener("scroll", listenScrollEvent)
    }
  }, [])
  return (
    <Header bgColor={color} boxShadow={shadow}>
      <Navbar />
    </Header>
  )
}
