import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

const Cookieconsent = () => {
  return (
    <CookieConsent
      location="bottom"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      //   enableDeclineButton
      // onAccept={() => {
      //   window[`UA-175881285-1`] = true;
      // }}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default Cookieconsent;
