import React from 'react';
import { createGlobalStyle } from 'styled-components';
import styled, { keyframes } from 'styled-components';
import './custom.css';

export const GlobalStyle = createGlobalStyle`
  body {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: 'Josefin Sans', sans-serif;
   

  }`;
const GlobalStyleProvider = ({ children }) => {
  return (
    <>
      <GlobalStyle theme='Hello' />

      {children}
    </>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;

  position: relative;

  justify-content: space-around;
  align-items: center;
  /* padding-top: 12rem; */
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Header = styled.div`
  width: 100%;
  height: inherit;
  background: ${(props) => props.bgColor};

  position: fixed;
  offset: 0;
  z-index: 10;
  transition: all 0.1s ease;
`;
export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center;
  margin: 0 auto; */
`;
/////////////////////////////
////////////Keyframes////////
/////////////////////////////
const zoomIn = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
`;
const moveIn = keyframes`
  0% {
   opacity: 0;
   top:0;
  }
  100% {
  
  opacity:1;
    transform: translateY(195px);
 }
`;
const fadeIn = keyframes`
  0% {
   opacity: 0;
   
  }
  100% {
  
  opacity: 1 ;
    
 }
`;

export const Backdrop = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: ${(props) => (!!props.height ? props.height : 'unset')};

  background: ${(props) =>
    !!props.overlay ? 'rgba(0, 0, 0, 0.26)' : '#f5c370'};
  min-height: 450px;
  /* background-attachment: fixed; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */

  &:before {
    content: '';
    background-image: url(${(props) => !!props.imgUrl && props.imgUrl});
    background-size: cover;
    background-position: 0px 0px;
    background-repeat: repeat-y;
    animation: ${zoomIn} 10s linear infinite alternate;
    width: 100%;

    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;
export const MenuBackdrop = styled(Backdrop)`
  background: rgba(0, 0, 0, 0.6);
  animation: unset;
`;
export const Main = styled.div`
  width: 100vw;
  overflow: hidden;
  flex: 1;
  margin: 0 auto;
`;
export const Footer = styled.div`
  position: relative;
  min-height: 200px;
  background: #753422;
  padding: 30px 0;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Button = styled.button`
  font-weight: 900;
  padding: 15px 20px 13px 20px;
  width: ${(props) => (props.width ? props.width : '')};
  /* min-width: 300px; */
  margin-left: auto;
  /* border-radius: 36px; */
  /* z-index: 1000; */
  color: white;
  background: ${(props) => (props.background ? props.background : '#b05b3b')};
  opacity: 0.8;
  border: 2px solid ${(props) => (props.color ? props.color : '#b05b3b')};
  &:hover {
    background-color: ${(props) =>
      props.background ? '#b05b3b' : 'transparent'};
    color: ${(props) => (props.color ? props.color : '#b05b3b')};
    border-color: ${(props) =>
      props.background ? 'transparent' : props.background};
  }
`;
export const Highlight = styled.div`
  width: 100%;
  height: 80vh;
  background: rgba(0, 0, 0, 0.3);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    offset: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.imgUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
`;
export const Card = styled.div`
  display: flex;
  padding: 10vh;
  width: 100%;
  /* height: 100vh; */
  max-width: 80%;
  box-shadow: 0 0 0 0 red;
  /* overflow: hidden; */
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    padding: 3rem 1rem;
  }
  .menu-card-main {
    z-index: 2;
    width: 70%;
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
    /* background: rgba(0, 0, 0, 0.5); */
  }
  .menu-wrap-image {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .menu-card-main-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .menu-card-main-item {
    width: 100%;

    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    &:hover {
      /* transform: scale(1.05); */
      box-shadow: 0px 0px 8px 2px #f5c370;
    }
  }
  .menu-card-main-add {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    span {
      display: block;
      flex: 1;
      border-top: 1px solid #f5c370;
      margin: 0 10px;
      margin-top: 17px;
    }
  }
  h1,
  p {
    color: black;
  }
  h5 {
    color: black;
  }

  .menu-card-main-image {
    position: absolute;
    /* right: -12rem; */
    z-index: -1;
  }
`;
export const BookATable = styled.div`
  background: #b05b3b;

  .book-card {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    min-width: 450px;
    h1 {
      font-family: 'Qwigley', cursive;
      text-align: center;
      margin: 20px 0;
      color: antiquewhite;
    }
  }

  label {
    width: 100%;
    max-width: 80%;
    display: flex;

    justify-content: space-between;
    margin: 5px auto;
    h3 {
      font-size: 18px;
      color: #ffebc9;
    }
    input {
      border: 2px solid #b05b3b;
      border-radius: 5px;
      width: 50%;
      padding: 5px;
    }
  }
  button {
    padding: 10px;
    border: none;
    width: 100%;

    background: transparent;
    font-weight: 700;
    .icon {
      margin-right: 10px;
    }
    &:hover {
      color: #ffebc9;
    }
  }
`;
export default GlobalStyleProvider;
