import React from "react"
import { Button } from "../styledComponent/sc"
import { navigate } from "gatsby"

export default function button({
  text,
  color,
  className,
  width,
  background,
  internalLink,
  externalLink,
}) {
  return (
    <Button
      className={className}
      color={color}
      width={width}
      background={background}
      onClick={() => {
        !!externalLink && window.open(externalLink, "__blank")
        !!internalLink && navigate(internalLink)
      }}
    >
      {text}
    </Button>
  )
}
