import React from 'react';
import { Link, navigate } from 'gatsby';
import { Navbar } from '../styledComponent/sc';
import { IoIosBasket } from '@react-icons/all-files/io/IoIosBasket';
import Button from './button';

export default function NavbarComponent() {
  return (
    <Navbar>
      <div className='nav-logo' onClick={() => navigate('/')}>
        <h1>Antoki</h1>
      </div>
      <div className='nav-link'>
        {/* <a href='https://www.bookable24.de/login'>
          <Button
            href='https://www.bookable24.de/login'
            // marginRight: 16,
            text='LOGIN'
            background='transparent'
            color='white'

            // onClick={() => window}
          >
            Login
          </Button>
        </a> */}
        <Button
          text='TISCH RESERVEREN'
          color='white'
          background='transparent'
          externalLink='https://www.bookable24.de/de/antoki5312394/'
        />

        {/* <Link className="gao-info" to="/cart">
          <IoIosBasket size="20" />
        </Link> */}
      </div>
    </Navbar>
  );
}
