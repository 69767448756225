import React from "react";
import MainHeader from "./header";
import Footer from "./footer";
import GlobalStyleProvider, { Main, Wrapper } from "../styledComponent/sc";
import Cookieconsent from "./cookieconsent";

export default function Layout({ children }) {
  return (
    <GlobalStyleProvider theme="hello">
      <Cookieconsent />

      <Wrapper>
        <MainHeader />
        <Main>{children}</Main>
        <Footer />
      </Wrapper>
    </GlobalStyleProvider>
  );
}
