import React from 'react';
import { Link } from 'gatsby';

import { GiSmartphone } from '@react-icons/all-files/gi/GiSmartphone';
import { GiGreenhouse } from '@react-icons/all-files/gi/GiGreenhouse';
import { HiOutlineMailOpen } from '@react-icons/all-files/hi/HiOutlineMailOpen';

import OpenTime from './opentime';
import { Footer } from '../styledComponent/sc';
export default function footer() {
  return (
    <Footer className='box-flex'>
      <div className='footer footer-left md6 sx12'>
        <h1>Antoki</h1>

        <p className='gao-info'>
          {' '}
          <GiSmartphone /> <span>0228 4222 5065</span>
        </p>
        <p className='gao-info'>
          {' '}
          <GiGreenhouse /> Wesselheideweg 101, 53123 Bonn{' '}
        </p>
        <p className='gao-info'>
          <HiOutlineMailOpen /> antokirestaurantbonn@gmail.com
        </p>
      </div>
      <div className='footer footer-right md6 sx12'>
        <OpenTime />
      </div>
      <div className='coppyright'>
        © {new Date().getFullYear()} Antoki
        <Link
          style={{ textDecoration: 'underline', paddingLeft: 10 }}
          to='/impressum'
        >
          Impressum
        </Link>{' '}
        <Link style={{ textDecoration: 'underline' }} to='/privacy'>
          Privacy
        </Link>
      </div>
    </Footer>
  );
}
