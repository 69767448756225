import React from 'react';

const OpenTime = () => {
  return (
    <div className='open-time'>
      {/* <b>Öffnungszeiten:</b> */}
      <h5>Öffnungszeiten</h5>
      <div className='open-time__item'>
        <p>Dienstag – Freitag:</p>
        <span> 12:00 – 15:00 & 17:00 – 22:30</span>
      </div>
      <div className='open-time__item'>
        <p>Samstag – Sonntag: </p>
        <span> 12:00 – 22:30</span>
      </div>
    </div>
  );
};

export default OpenTime;
